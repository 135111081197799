import styles from './FooterLigaPago.module.css';

export default function FooterLigaPago() {
  return (
    <div className={styles['container-footer-recibos']} style={{ zIndex: (theme) => theme.zIndex.appBar + 2 }}>
      <div className={styles['footer-copyright']}>
        <label>Copyright 2025 ® Interprotección</label>
      </div>
    </div>
  );
}
